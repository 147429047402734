import React, { useEffect, useContext } from "react";
import toastr from "toastr";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { appContext } from "../App";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import { Select } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { blue } from "@material-ui/core/colors";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

const GreenCheckbox = withStyles({
  root: {
    color: blue[800],
    "&$checked": {
      color: blue[800],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const EditQuoteModal = (props) => {
  const classes = useStyles();
  const { authUser, setAuthUser } = useContext(appContext);
  const [quote, setQuote] = React.useState(props.quote);
  const [purchaseDate, setPurchaseDate] = React.useState(new Date());

  useEffect(() => {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: false,
      positionClass: "toast-top-right",
      preventDuplicates: false,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
  }, []);

  const convertToDate = (date) => {
    if (date) {
      var dd = String(date.getDate()).padStart(2, "0");
      var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = date.getFullYear();
      return dd + "/" + mm + "/" + yyyy;
    } else return "";
  };

  const getBooleanValue = (val) => {
    if (val === "T + 1" || !val) return false;
    else return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (authUser && authUser.uuid) {
      let currentState = quote;
      currentState.purchaseDate = convertToDate(purchaseDate);
      currentState.isCurrentDay = getBooleanValue(currentState.isCurrentDay);
      currentState.isCurrentDayPurchaseDate = getBooleanValue(
        currentState.isCurrentDayPurchaseDate
      );
      setQuote(props.quote);
      setPurchaseDate(new Date());
      props.onUpdate(event, currentState);
    }
  };

  const handleISINChange = (event, enteredValue) => {
    event.preventDefault();
    let currentState = quote;
    let filteredSecurityName = getFilteredSecurityName(enteredValue);
    currentState.isin = enteredValue;
    if (filteredSecurityName !== undefined) {
      currentState.securityName = filteredSecurityName.securityName;
    } else currentState.securityName = "";
    setQuote(currentState);
  };

  const getFilteredSecurityName = (isin) => {
    return props.mappings.filter((mapping) => mapping.isin === isin)[0];
  };

  const resetStateAndClose = (event, reason) => {
    event.preventDefault();
    if (reason && reason === "backdropClick") {
      return;
    }
    setQuote(props.initialState);
    props.onClose(event);
  };

  const renderISINInput = () => {
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          disabled={true}
          options={props.isinNumbers}
          onChange={(event, enteredValue) =>
            handleISINChange(event, enteredValue)
          }
          autoHighlight={true}
          value={quote.isin}
          renderInput={(params) => (
            <TextField
              {...params}
              label="ISIN"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  };

  const getFilteredISIN = (securityName) => {
    return props.mappings.filter(
      (mapping) => mapping.securityName === securityName
    )[0];
  };

  const handleSecurityNameChange = (event, enteredValue) => {
    event.preventDefault();
    let currentState = quote;
    let filteredISIN = getFilteredISIN(enteredValue);
    currentState.securityName = enteredValue;
    if (filteredISIN !== undefined) {
      currentState.isin = filteredISIN.isin;
    } else currentState.isin = "";
    setQuote(currentState);
  };

  const renderSecurityNameInput = () => {
    return (
      <Grid item xs={12}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          disabled={true}
          options={props.securityNames}
          onChange={(event, enteredValue) =>
            handleSecurityNameChange(event, enteredValue)
          }
          autoHighlight={true}
          value={quote.securityName}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Security Name"
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  };

  const handleChange = (event) => {
    event.persist();

    setQuote((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePurchaseDateChange = (date) => {
    setPurchaseDate(date);
  };

  const renderPurchaseDateAndPurchasePrice = () => {
    let purchaseDateOptions = ["T + 0", "T + 1"];
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item justify="space-around" xs={8}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Purchase Date"
                  format="dd/MM/yyyy"
                  value={purchaseDate}
                  onChange={handlePurchaseDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={6} style={{ "margin-top": "10px" }}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              style={{ width: "100%" }}
            >
              <InputLabel id="isCurrentDayPurchaseDate">
                Purchase Date T+0/T+1
              </InputLabel>
              <Select
                labelId="isCurrentDayPurchaseDate"
                id="isCurrentDayPurchaseDate"
                name="isCurrentDayPurchaseDate"
                value={
                  quote.isCurrentDayPurchaseDate === "T + 0" ||
                  quote.isCurrentDayPurchaseDate
                    ? "T + 0"
                    : "T + 1"
                }
                onChange={(event) => handleChange(event)}
                label="T+0/T+1"
              >
                {purchaseDateOptions &&
                  purchaseDateOptions.length > 0 &&
                  purchaseDateOptions.map((purchaseDateOption, index) => {
                    return (
                      <MenuItem value={purchaseDateOption} key={index}>
                        {purchaseDateOption}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const renderPurchaseYield = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="purchaseYield"
            variant="outlined"
            fullWidth
            label="Purchase Yield"
            defaultValue={quote.purchaseYield}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="text"
            name="purchasePrice"
            variant="outlined"
            fullWidth
            label="Purchase Price"
            defaultValue={quote.purchasePrice}
            onChange={(event) => handleChange(event)}
          />
        </Grid>
      </Grid>
    );
  };

  const renderSpread = () => {
    return (
      <TextField
        style={{ "margin-top": "10px" }}
        type="text"
        name="spread"
        variant="outlined"
        fullWidth
        label="Spread"
        defaultValue={quote.spread}
        onChange={(event) => handleChange(event)}
      />
    );
  };

  const renderMinAndMaxQuantity = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <React.Fragment>
          <Grid item xs={6} style={{ "margin-top": "10px" }}>
            <TextField
              type="text"
              name="minimumQuantity"
              variant="outlined"
              fullWidth
              label="Minimum Quantity"
              defaultValue={quote.minimumQuantity}
              onChange={(event) => handleChange(event)}
            />
          </Grid>
          <Grid item xs={6} style={{ "margin-top": "10px" }}>
            <TextField
              type="text"
              name="maximumQuantity"
              variant="outlined"
              fullWidth
              label="Maximum Quantity"
              defaultValue={quote.maximumQuantity}
              onChange={(event) => handleChange(event)}
            />
          </Grid>
        </React.Fragment>
      </Grid>
    );
  };

  const handleCheckBox = (event) => {
    event.persist();
    setQuote((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.checked,
    }));
  };

  const renderCheckboxes = () => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={6} style={{ "margin-top": "10px" }}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={quote.isBookStock}
                onChange={handleCheckBox}
                name="isBookStock"
              />
            }
            label="Is Book Stock"
          />
        </Grid>
        <Grid item xs={6} style={{ "margin-top": "10px" }}>
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={quote.isHotDeal}
                onChange={handleCheckBox}
                name="isHotDeal"
              />
            }
            label="Is Hot Deal"
          />
        </Grid>
      </Grid>
    );
  };

  const renderIsCurrentDayDropdown = () => {
    let currentDayOptions = ["T + 0", "T + 1"];
    return (
      <Grid item xs={6} style={{ "margin-top": "10px" }}>
        <FormControl
          variant="outlined"
          className={classes.formControl}
          style={{ width: "100%" }}
        >
          <InputLabel id="demo-simple-select-outlined-label">
            T+0/T+1
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            name="isCurrentDay"
            value={
              quote.isCurrentDay === "T + 0" || quote.isCurrentDay
                ? "T + 0"
                : "T + 1"
            }
            onChange={(event) => handleChange(event)}
            label="T+0/T+1"
          >
            {currentDayOptions &&
              currentDayOptions.length > 0 &&
              currentDayOptions.map((currentDayOption, index) => {
                return (
                  <MenuItem value={currentDayOption} key={index}>
                    {currentDayOption}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={(event, reason) => resetStateAndClose(event, reason)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Quote</DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            {renderISINInput()}
            {renderSecurityNameInput()}
            {renderPurchaseDateAndPurchasePrice()}
            {renderPurchaseYield()}
            {renderSpread()}
            {renderMinAndMaxQuantity()}
            {renderIsCurrentDayDropdown()}
            {renderCheckboxes()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(event, reason) => resetStateAndClose(event, reason)}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={(event) => handleSubmit(event)}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditQuoteModal;
