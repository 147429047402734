import React, { createContext, useEffect, useState } from "react";
import "./App.css";
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@material-ui/core/styles";
import MainRouter from "./routers/MainRouter";
import { CssBaseline } from "@material-ui/core";
import ReactDOM from "react-dom";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

const font1 = "'Lexend', sans-serif";
const font2 = "'DM Serif Display', sans-serif";

export let theme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      labelAsterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#262349", //default orange
    },
    secondary: {
      main: "#FBFBFB", //whitish bg
    },
    tertiary: {
      main: "#14213D", //dark blue - text & bg
    },
    defaultWhite: {
      main: "#FFF",
    },
    defaultBlack: {
      main: "#000",
    },
    text: {
      main: "#707070",
    },
  },
  typography: {
    a: {
      fontFamily: font1,
      fontSize: 14,
    },
    b: {
      fontFamily: font2,
      fontSize: 18,
    },
  },
});

theme = responsiveFontSizes(theme);

export const appContext = createContext();

function App() {
  const [authUser, setAuthUser] = useState(null);
  useEffect(() => {
    document.title = "SMEST Admin";

    var parsedAuthUser;
    if (!authUser) {
      const localAuthUser = localStorage.getItem("loginUser");
      if (localAuthUser) {
        parsedAuthUser = JSON.parse(localAuthUser);
        setAuthUser(parsedAuthUser);
      }
    } else {
      parsedAuthUser = authUser;
    }
  }, [authUser]);

  return (
    <div>
      <ThemeProvider theme={theme}>
        <appContext.Provider
          value={{
            authUser,
            setAuthUser,
          }}
        >
          <CssBaseline />
          <MainRouter />
        </appContext.Provider>
      </ThemeProvider>
    </div>
  );
}
//
// Sentry.init({
//   dsn: process.env.REACT_APP_SENTRY_PROJECT_DSN,
//   integrations: [new BrowserTracing()],
//
//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

// const disableReactDevTools = () => {
//   const noop = () => undefined;
//   const DEV_TOOLS = window.__REACT_DEVTOOLS_GLOBAL_HOOK__;
//
//   if (typeof DEV_TOOLS === "object") {
//     for (const [key, value] of Object.entries(DEV_TOOLS)) {
//       DEV_TOOLS[key] = typeof value === "function" ? noop : null;
//     }
//   }
// };

// disableReactDevTools();
ReactDOM.render(<App />, document.getElementById("root"));
export default App;
