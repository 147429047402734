import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import FamilyMembersAccountTab from "./FamilyMembersAccountTab";
import PortfolioHolding from "../Portfolio/PortfolioHolding";
import OrderHistoryTable from "./Orders/OrderHistory";
import NomineeDetailsTab from "./Nominee/NomineeDetailsTab";
import CashFlowRecordsTab from "../CashFlowRecords/CashFlowRecordsTab";
import NewKYCDetailsTab from "./NewKYCDetailsTab";
import SearchLogs from "../SearchLogs/SearchLogs";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserDetailsDialog = (props) => {
  const classes = useStyles();
  const [currentTab, setCurrentTab] = React.useState(0);
  const isSuperuser = JSON.parse(localStorage.getItem("loginUser")).isSuperuser;

  const handleChange = (event, selectedTab) => {
    setCurrentTab(selectedTab);
  };

  return (
    <div>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {`${props.currentUser.firstName} ${props.currentUser.lastName}`}
            </Typography>
            <Button autoFocus color="inherit" onClick={props.handleClose}>
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              value={currentTab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="KYC" {...a11yProps(0)} />
              <Tab label="Order History" {...a11yProps(1)} />
              <Tab label="Portfolio" {...a11yProps(2)} />
              <Tab label="Family Members" {...a11yProps(3)} />
              <Tab label="Nominee" {...a11yProps(4)} />
              <Tab label="CashFlow Records" {...a11yProps(5)} />
              <Tab label="Search Logs" {...a11yProps(6)} />
            </Tabs>
          </AppBar>
          <TabPanel value={currentTab} index={0}>
            <NewKYCDetailsTab currentUser={props.currentUser} />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <OrderHistoryTable currentUser={props.currentUser} />
          </TabPanel>
          <TabPanel value={currentTab} index={2}>
            <PortfolioHolding currentUser={props.currentUser} />
          </TabPanel>
          <TabPanel value={currentTab} index={3}>
            <FamilyMembersAccountTab currentUser={props.currentUser} />
          </TabPanel>
          <TabPanel value={currentTab} index={4}>
            <NomineeDetailsTab currentUser={props.currentUser} />
          </TabPanel>

          <TabPanel value={currentTab} index={5}>
            <CashFlowRecordsTab currentUser={props.currentUser} />
          </TabPanel>

          <TabPanel value={currentTab} index={6}>
            <SearchLogs currentUser={props.currentUser} />
          </TabPanel>
        </div>
      </Dialog>
    </div>
  );
};

export default UserDetailsDialog;
