import React from "react";
import {
  makeDELETERequest,
  makeGETRequest,
  makePOSTRequest,
  makePUTRequest,
} from "../../Api/api";
import {
  IPOSecurityMasterInterface,
  SecurityMasterRecord,
  AllocationRatio,
} from "./Interfaces";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { addSerialNumber } from "../../Helpers/SecurityMasterHelpers";
import FullScreenDialog from "../../components/Dialog/FullScreenDialog";
import CreateIPOSecurityMaster from "../../components/IPOSecurityMaster/CreateIPOSecurityMaster";
import GenericDialog from "../../components/Dialog/GenericDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PostAddRoundedIcon from "@mui/icons-material/PostAddRounded";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { StyledTextInput } from "../../components/StyledComponents/StyledInputs";

const toastr = require("toastr");

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ipoSecurityMasterDataGridCells = [
  { field: "serial", headerName: "Serial", width: 150, editable: false },
  {
    field: "issueDescription",
    headerName: "Issue Description",
    width: 200,
    editable: true,
  },
  { field: "scripId", headerName: "Scrip ID", width: 150, editable: true },
  {
    field: "issueOpenDate",
    headerName: "Issue Open Date",
    width: 150,
    editable: true,
  },
  {
    field: "issueCloseDate",
    headerName: "Issue Close Date",
    width: 150,
    editable: true,
  },
  {
    field: "natureOfInstrument",
    headerName: "Nature of Instrument",
    width: 200,
    editable: true,
  },
  {
    field: "faceValue",
    headerName: "Face Value",
    width: 150,
    editable: true,
  },
  {
    field: "faceValueUnit",
    headerName: "Face Value Unit",
    width: 150,
    editable: true,
  },
  {
    field: "pricePerBond",
    headerName: "Price Per Bond",
    width: 150,
    editable: true,
  },
  {
    field: "pricePerBondUnit",
    headerName: "Price Per Bond Unit",
    width: 200,
    editable: true,
  },
  {
    field: "nriEligibility",
    headerName: "NRI Eligibility",
    width: 150,
    editable: true,
  },
  {
    field: "taxCategory",
    headerName: "Tax Category",
    width: 150,
    editable: true,
  },
  {
    field: "guaranteeType",
    headerName: "Guarantee Type",
    width: 150,
    editable: true,
  },
  { field: "sector", headerName: "Sector", width: 150, editable: true },
  {
    field: "securedOrUnsecured",
    headerName: "Secured/Unsecured",
    width: 200,
    editable: true,
  },
  {
    field: "issueSize",
    headerName: "Issue Size",
    width: 150,
    editable: true,
  },
  {
    field: "maxInterestDescription",
    headerName: "Max Interest Description",
    width: 250,
    editable: true,
  },
  {
    field: "bpsOverBankFdsDescription",
    headerName: "BPS Over Bank FDS Description",
    width: 300,
    editable: true,
  },
  {
    field: "delete",
    headerName: "Delete",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: (row: any) => {
      return (
        <DeleteIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "edit",
    headerName: "Edit",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,

    renderCell: () => {
      return (
        <EditIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
  {
    field: "upload",
    headerName: "Upload",
    sortable: false,
    width: 100,
    disableClickEventBubbling: true,
    renderCell: () => {
      return (
        <PostAddRoundedIcon
          color="primary"
          fontSize="medium"
          style={{
            float: "left",
            marginLeft: "10px",
            marginTop: "14px",
            cursor: "pointer",
          }}
        />
      );
    },
  },
];

export default function IPOSecurityMaster(props: any) {
  const [ipoSecurityMasters, setIPOSecurityMasters] = React.useState<
    SecurityMasterRecord[]
  >([]);
  const [showAddIPOSecurityMasterModal, setShowAddIPOSecurityMasterModal] =
    React.useState<boolean>(false);
  const ipoSecurityMasterInitialState = {
    uin: "",
    issueDescription: "",
    scripId: "",
    issueOpenDate: "",
    issueCloseDate: "",
    natureOfInstrument: "",
    issuerOfSecurity: 0,
    faceValue: 0,
    faceValueUnit: "",
    pricePerBond: 0,
    pricePerBondUnit: "",
    nriEligibility: false,
    taxCategory: "",
    guaranteeType: "",
    sector: "",
    securedOrUnsecured: "",
    issueSize: "",
    maxInterestDescription: "",
    bpsOverBankFdsDescription: "",
    allocationRatio: [],
  };
  const [initialState, setInitialState] =
    React.useState<IPOSecurityMasterInterface>(ipoSecurityMasterInitialState);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    React.useState<boolean>(false);
  const [toBeDeleted, setToBeDeleted] = React.useState<string>("");
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [
    currentlySelectedRatingOrganizationMasters,
    setCurrentlySelectedRatingOrganizationMasters,
  ] = React.useState<any[]>([]);
  const [uploadedFile, setUploadedFile] = React.useState<File | null>(null);
  const [allocationRatio, setAllocationRatio] = React.useState<
    AllocationRatio[]
  >([]);
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState("fetching");
  const [loadCount, setLoadCount] = React.useState(false);
  const [id, setID] = React.useState("");
  const [excel, setExcel] = React.useState<File | null>(null);

  function addAllocationRatioRecord(record: AllocationRatio) {
    if (record) {
      let currentState = allocationRatio;
      record.id = allocationRatio.length + 1;
      currentState.push(record);
      setAllocationRatio(currentState);
    }
  }

  function removeAllocationRatioRecord(id: number) {
    if (id) {
      setAllocationRatio(
        allocationRatio.filter(
          (allocationRatio: AllocationRatio) => allocationRatio.id !== id
        )
      );
    }
  }

  function updateAllocationRatioRecord(row: AllocationRatio) {
    let filtered = allocationRatio.filter(
      (allocationRatio: AllocationRatio) => allocationRatio.id !== row.id
    );
    filtered.push(row);
    filtered.sort((a: AllocationRatio, b: AllocationRatio) => a.id - b.id);
    setAllocationRatio(filtered);
  }

  function deleteIPOSecurityMaster(event: React.MouseEvent): void {
    makeDELETERequest(`/ipo/security_master/delete/${toBeDeleted}`)
      .then((response: any) => {
        setIPOSecurityMasters(
          ipoSecurityMasters.filter(
            (securityMaster: any) => securityMaster.uuid !== toBeDeleted
          )
        );
        setToBeDeleted("");
        setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
        toastr.success("Deleted IPO Security Master successfully!");
      })
      .catch((error: any) => toastr.error(error));
  }

  function handleEditIconOnClick(
    currentlySelected: IPOSecurityMasterInterface
  ) {
    let filteredSecurityMaster = ipoSecurityMasters.filter(
      (ipoSecurityMaster: any) =>
        ipoSecurityMaster.ipoSecurityMaster.uuid === currentlySelected.uuid
    )[0];
    if (typeof currentlySelected.allocationRatio === "string") {
      try {
        setAllocationRatio(JSON.parse(currentlySelected.allocationRatio));
      } catch (err) {
        console.error(err);
        setAllocationRatio([]);
      }
    } else {
      setAllocationRatio(currentlySelected.allocationRatio);
    }

    setCurrentlySelectedRatingOrganizationMasters(
      filteredSecurityMaster.ratingOrganizationRelationships
    );
    setInitialState(currentlySelected);
    setShowAddIPOSecurityMasterModal(true);
    setEditMode(true);
  }

  function handleDeleteIconOnClick(ipoSecurityMasterUUID: string): void {
    setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
    setToBeDeleted(ipoSecurityMasterUUID);
  }

  function snakeToCamel(obj: any): any {
    let formattedOrganizationMaster = obj.rating_organization_relationships.map(
      (relationshipObject: any) => ({
        organizationName:
          relationshipObject.rating_organization.organization.name,
        rating: relationshipObject.rating_organization.rating.rating,
        date: relationshipObject.rating_organization.rating_date,
        uuid: relationshipObject.uuid,
      })
    );
    let converted = {
      ipoSecurityMaster: {
        uuid: obj.ipo_security_master.uuid,
        uin: obj.ipo_security_master.uin,
        issueDescription: obj.ipo_security_master.issue_description,
        scripId: obj.ipo_security_master.scrip_id,
        issueOpenDate: obj.ipo_security_master.issue_open_date,
        issueCloseDate: obj.ipo_security_master.issue_close_date,
        natureOfInstrument: obj.ipo_security_master.nature_of_instrument,
        faceValue: obj.ipo_security_master.face_value,
        faceValueUnit: obj.ipo_security_master.face_value_unit,
        pricePerBond: obj.ipo_security_master.price_per_bond,
        pricePerBondUnit: obj.ipo_security_master.price_per_bond_unit,
        nriEligibility: obj.ipo_security_master.nri_eligibility,
        taxCategory: obj.ipo_security_master.tax_category,
        guaranteeType: obj.ipo_security_master.guarantee_type,
        sector: obj.ipo_security_master.sector,
        securedOrUnsecured: obj.ipo_security_master.secured_or_unsecured,
        issueSize: obj.ipo_security_master.issue_size,
        maxInterestDescription:
          obj.ipo_security_master.max_interest_description,
        bpsOverBankFdsDescription:
          obj.ipo_security_master.bps_over_bank_fds_description,
        issuerOfSecurity: obj.ipo_security_master.issuer_of_security.uuid,
        allocationRatio: obj.ipo_security_master.allocation_ratio,
        productNoteFileName: obj.ipo_security_master.product_note_file_name,
      },
      ratingOrganizationRelationships: formattedOrganizationMaster,
    };
    return converted;
  }

  function convertListToCamelCase(
    objList: IPOSecurityMasterInterface[]
  ): IPOSecurityMasterInterface[] {
    return objList.map((obj) => snakeToCamel(obj));
  }

  React.useEffect(() => {
    makeGETRequest("/ipo/security_master/list/")
      .then((response) => {
        setIPOSecurityMasters(
          addSerialNumber(convertListToCamelCase(response.data))
        );
      })
      .catch((error) => toastr.error(error));
  }, []);

  function convertDateToYYYYMMDD(dateString: string) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    }

    const parts = dateString.split("/");
    const day = parts[0].padStart(2, "0");
    const month = parts[1].padStart(2, "0");
    const year = parts[2];
    return `${year}-${month}-${day}`;
  }

  function getFormattedStateForSecurityMaster(currentState: any): any {
    if (typeof currentState.nriEligibility !== "boolean")
      currentState.nriEligibility =
        currentState.nriEligibility.toLowerCase() === "yes" ? true : false;
    let formData = new FormData();
    formData.append(
      "bps_over_bank_fds_description",
      currentState.bpsOverBankFdsDescription
    );
    formData.append("face_value", currentState.faceValue);
    formData.append("face_value_unit", currentState.faceValueUnit);
    formData.append("guarantee_type", currentState.guaranteeType);
    formData.append(
      "issue_close_date",
      convertDateToYYYYMMDD(currentState.issueCloseDate)
    );
    formData.append("issue_description", currentState.issueDescription);
    formData.append(
      "issue_open_date",
      convertDateToYYYYMMDD(currentState.issueOpenDate)
    );
    formData.append("issue_size", currentState.issueSize);
    formData.append("issuer_name", currentState.issuerName);
    formData.append(
      "issuer_of_security",
      currentState.issuerOfSecurityUUID || currentState.issuerOfSecurity
    );
    formData.append("issuer_type", currentState.issuerType);
    formData.append(
      "max_interest_description",
      currentState.maxInterestDescription
    );
    formData.append("nature_of_instrument", currentState.natureOfInstrument);
    formData.append("nri_eligibility", currentState.nriEligibility);
    formData.append("price_per_bond", currentState.pricePerBond);
    formData.append("price_per_bond_unit", currentState.pricePerBondUnit);
    formData.append("scrip_id", currentState.scripId);
    formData.append("sector", currentState.sector);
    formData.append("secured_or_unsecured", currentState.securedOrUnsecured);
    formData.append("tax_category", currentState.taxCategory);
    formData.append("uin", currentState.uin);
    formData.append("product_note_file", uploadedFile || "");
    formData.append(
      "product_note_file_name",
      uploadedFile ? uploadedFile.name : ""
    );
    formData.append("allocation_ratio", JSON.stringify(allocationRatio));

    return formData;
  }

  function handleIPOSecurityMasterCreate(event: any, ipoSecurityMaster: any) {
    event.preventDefault();
    if (editMode) {
      makePUTRequest(
        `/ipo/security_master/update/${initialState?.uuid}/`,
        getFormattedStateForSecurityMaster(initialState)
      )
        .then((resp) => {
          if (resp.status === 200) {
            toastr.success("IPO Security Master updated successfully!");
            setInitialState(ipoSecurityMasterInitialState);
            setShowAddIPOSecurityMasterModal(false);
          }
        })
        .catch((error) => toastr.error(error));
    } else {
      makePOSTRequest(
        "/ipo/security_master/create/",
        getFormattedStateForSecurityMaster(initialState)
      )
        .then((response: any) => {
          toastr.success("Created IPO Security Master successfully!");
          setInitialState(ipoSecurityMasterInitialState);
          setIPOSecurityMasters(
            addSerialNumber(convertListToCamelCase(response.data))
          );
          setShowAddIPOSecurityMasterModal(false);
        })
        .catch((error: any) => toastr.error(error));
    }
  }

  function handleChange(event: any) {
    event.persist();
    setInitialState((previousValues: any) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  React.useEffect(() => {
    loadCount &&
      makeGETRequest(`/ipo/application_number/count/get/${id}/`)
        .then((response) => {
          setCount(response.data.application_count);
        })
        .catch((error) => toastr.error(error));
    return () => setLoadCount(false);
  }, [loadCount]);

  const handleUpload = () => {
    let formData = new FormData();
    formData.append("application_no", excel!);
    formData.append("ipo_security", id);
    makePOSTRequest(`/ipo/application_number/upload/`, formData)
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Excel Uploaded Successfully");
        }
      })
      .catch((error) => toastr.error(error));
  };

  function currentlySelected(params: any) {
    if (params.field === "delete") {
      handleDeleteIconOnClick(params.row.uuid);
    } else if (params.field === "edit") {
      handleEditIconOnClick(params.row);
    } else if (params.field === "upload") {
      setID(params.row.uuid);
      setOpen(true);
      setLoadCount(true);
    }
  }

  return (
    <>
      <Grid className="securityTypeDataGird">
        <Paper>
          <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
            <Grid container alignItems="center" spacing={3}>
              <Grid item xs>
                <div style={{ fontSize: "36px", float: "left" }}>
                  IPO Security Master
                </div>
              </Grid>
              <Grid item xs="auto">
                <AddCircleIcon
                  color="primary"
                  fontSize="large"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowAddIPOSecurityMasterModal(
                      !showAddIPOSecurityMasterModal
                    );
                  }}
                  style={{
                    float: "right",
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <div style={{ height: "100%", width: "100%" }}>
            <DataGrid
              className="securityTypeDataGrid abc"
              onCellClick={currentlySelected}
              components={{ Toolbar: GridToolbar }}
              rows={ipoSecurityMasters.map(
                (resp: any) => resp.ipoSecurityMaster
              )}
              columns={ipoSecurityMasterDataGridCells}
              getRowId={(row) => row.uuid}
              autoHeight
            />
          </div>
        </Paper>
        {showAddIPOSecurityMasterModal ? (
          <FullScreenDialog
            show={showAddIPOSecurityMasterModal}
            handleClose={(event: any) => {
              event.preventDefault();
              setShowAddIPOSecurityMasterModal(!showAddIPOSecurityMasterModal);
            }}
            showSaveButton={true}
            saveButtonText="Create"
            onSumitSaveButton={handleIPOSecurityMasterCreate}
            inputProps={
              <CreateIPOSecurityMaster
                handleChange={handleChange}
                initialState={initialState}
                allocationRatio={allocationRatio}
                addAllocationRatioRecord={addAllocationRatioRecord}
                removeAllocationRatioRecord={removeAllocationRatioRecord}
                updateAllocationRatioRecord={updateAllocationRatioRecord}
                setInitialState={setInitialState}
                savedRatingOrganizationMasters={
                  currentlySelectedRatingOrganizationMasters
                }
                handleFileChange={(event: any) => {
                  setUploadedFile(event.target.files[0]);
                }}
                setSavedRatingOrganizationMasters={
                  setCurrentlySelectedRatingOrganizationMasters
                }
                ipoSecurityMasterId={editMode ? initialState.uuid : ""}
              />
            }
            heading="Create IPO Security Master"
          />
        ) : (
          ""
        )}
        {showDeleteConfirmationModal ? (
          <GenericDialog
            show={showDeleteConfirmationModal}
            heading="Delete IPO Security Master"
            inputProps="Are you sure you want to delete this security master?"
            onClose={(event: React.MouseEvent) => {
              event.preventDefault();
              setShowDeleteConfirmationModal(!showDeleteConfirmationModal);
              setToBeDeleted("");
            }}
            handleSubmit={deleteIPOSecurityMaster}
          />
        ) : (
          ""
        )}
      </Grid>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setOpen(false);
          setCount("fetching");
          setExcel(null);
          setID("");
          setLoadCount(false);
        }}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Upload Excel File</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FormControl variant="standard" style={{ marginTop: 10 }}>
            <InputLabel shrink htmlFor="ucc">
              Current Count
            </InputLabel>
            <StyledTextInput id="count" disabled value={count} />
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              component="label"
              role={"upload"}
              variant="contained"
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
              color={excel ? "success" : "primary"}
            >
              {excel ? "Uploaded" : "Upload Excel"}
              <VisuallyHiddenInput
                type="file"
                onChange={(event) => {
                  if (event.target.files && event.target.files.length > 0) {
                    setExcel(event.target.files[0]);
                  } else {
                    setExcel(null);
                  }
                }}
              />
            </Button>
            {excel && (
              <IconButton
                sx={{ ml: 1, color: "#7659EE", p: 0 }}
                onClick={handleUpload}
              >
                <ArrowCircleRightTwoToneIcon fontSize="large" />
              </IconButton>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
