import React, { ReactNode, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { alpha, styled } from "@mui/material/styles";
import PlaylistAddRoundedIcon from "@mui/icons-material/PlaylistAddRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DoneAllRoundedIcon from "@mui/icons-material/DoneAllRounded";
import AccountBalanceRoundedIcon from "@mui/icons-material/AccountBalanceRounded";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import DownloadForOfflineTwoToneIcon from "@mui/icons-material/DownloadForOfflineTwoTone";
import InputAdornment from "@mui/material/InputAdornment";
import DrawTwoToneIcon from "@mui/icons-material/DrawTwoTone";
import CopyAllTwoToneIcon from "@mui/icons-material/CopyAllTwoTone";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  downloadUserServiceFileUsingGET,
  makeDELETERequestOnUserServiceWithQueryParameter,
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import toastr from "toastr";
import {
  StyledSelectInput,
  StyledTextInput,
} from "../StyledComponents/StyledInputs";
import { RouteComponentProps, withRouter, useParams } from "react-router-dom";
import { OrderDetailsInterface } from "./interfaces";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import FormHelperText from "@mui/material/FormHelperText";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { saveAs } from "file-saver";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import copy from "copy-to-clipboard";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledButton = styled(Button)({
  borderRadius: 10,
});

interface ActionButtons {
  name: string;
  color:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning";
  icon: ReactNode;
  disabled: boolean;
  helperText: string;
  handleClick: () => void;
}

const IFA_FEES = [
  { label: "Source", icon: "none" },
  { label: "Fee", icon: "none" },
];

interface IModifyValues {
  value: number;
  sellYield: number;
  sellPrice: number;
  minimumOrderValue: number;
  principalAmount: number;
  modifiedAccruedInterest: number;
  stampDuty: number;
  totalConsideration: number;
  noOfBonds: number;
  settlementType: number;
  buyerClientName: string;
  bondType: string;
  bidOffer: string;
  initiatorIfsc: string;
  initiatorBankAccountNumber: string;
  initiatorDpType: string;
  initiatorDpid: string;
  initiatorClientId: string;
  settlementDate: string;
}

function isValueString(value: string) {
  return [
    "buyerClientName",
    "bondType",
    "bidOffer",
    "initiatorIfsc",
    "initiatorBankAccountNumber",
    "initiatorDpType",
    "initiatorDpid",
    "initiatorClientId",
    "settlementDate",
  ].includes(value);
}

const RFQOrderDetails: React.FC<RouteComponentProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [dealStatus, setDealStatus] = useState("Pending");
  const [invoiceCleared, setInvoiceCleared] = useState("NO");
  const [orderDetails, setOrderDetails] =
    useState<OrderDetailsInterface | null>(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [internally, setInternally] = useState(false);
  const initialModifyValues = useMemo(
    () => ({
      value: orderDetails?.value!,
      sellYield: orderDetails?.sell_yield!,
      sellPrice: orderDetails?.sell_price!,
      minimumOrderValue: orderDetails?.minimum_order_value!,
      principalAmount: orderDetails?.principal_amount!,
      modifiedAccruedInterest: orderDetails?.modified_accrued_interest!,
      stampDuty: orderDetails?.stamp_duty!,
      totalConsideration: orderDetails?.total_consideration!,
      noOfBonds: orderDetails?.no_of_bonds!,
      buyerClientName: orderDetails?.buyer_client_name!,
      bondType: orderDetails?.bond_type!,
      bidOffer: orderDetails?.bid_offer!,
      initiatorIfsc: orderDetails?.initiator_ifsc!,
      initiatorBankAccountNumber: orderDetails?.initiator_bank_account_number!,
      initiatorDpType: orderDetails?.initiator_dp_type!,
      initiatorDpid: orderDetails?.initiator_dpid!,
      initiatorClientId: orderDetails?.initiator_client_id!,
      settlementDate: moment(
        orderDetails?.settlement_date!,
        "MMM DD, YYYY"
      ).format("YYYY-MM-DD"),
      settlementType: orderDetails?.settlement_type!,
    }),
    [orderDetails]
  );
  const [modifyValues, setModifyValues] = useState(initialModifyValues);

  const {
    orderUUID,
  }: {
    orderUUID: string;
  } = useParams();

  const handleDealStatusSelect = (event: SelectChangeEvent) => {
    setDealStatus(event.target.value as string);
  };

  const handleInvoiceClearedSelect = (event: SelectChangeEvent) => {
    setInvoiceCleared(event.target.value as string);
  };

  const handleAddRFQ = () => {
    makePOSTRequestOnUserService("order_management_v2/orders/add-to-rfq/", {
      uuid: orderUUID,
      admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("RFQ Added Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleAcceptRFQ = () => {
    makePOSTRequestOnUserService(
      `/order_management_v2/orders/accept_order/${orderUUID}/`,
      {
        ModAcrInt: orderDetails?.modified_accrued_interest,
        TotalConsideration: orderDetails?.total_consideration,
        admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
      }
    )
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (data && data.RFQQuoteAcceptResponceList[0]?.MESSAGE) {
            toastr.success(data.RFQQuoteAcceptResponceList[0]?.MESSAGE);
          } else {
            toastr.success("RFQ Accepted Successfully");
          }
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleModify = () => {
    makePUTRequestOnUserService(`/order_management_v2/orders/`, {
      uuid: orderUUID,
      isin: orderDetails?.isin,
      value: modifyValues.value,
      minimum_order_value: modifyValues.minimumOrderValue,
      sell_yield: modifyValues.sellYield,
      sell_price: modifyValues.sellPrice,
      principal_amount: modifyValues.principalAmount,
      modified_accrued_interest: modifyValues.modifiedAccruedInterest,
      stamp_duty: modifyValues.stampDuty,
      total_consideration: modifyValues.totalConsideration,
      admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
    })
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("RFQ Modified Successfully");
          setLoading(true);
          setOpen(false);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleModifyInternally = () => {
    makePUTRequestOnUserService(
      `/order_management_v2/rfq_modified_internally/`,
      {
        uuid: orderUUID,
        isin: orderDetails?.isin,
        value: modifyValues.value,
        minimum_order_value: modifyValues.minimumOrderValue,
        sell_yield: modifyValues.sellYield,
        sell_price: modifyValues.sellPrice,
        principal_amount: modifyValues.principalAmount,
        modified_accrued_interest: modifyValues.modifiedAccruedInterest,
        stamp_duty: modifyValues.stampDuty,
        total_consideration: modifyValues.totalConsideration,
        buyer_client_name: modifyValues.buyerClientName,
        admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
        no_of_bonds: modifyValues.noOfBonds,
        bond_type: modifyValues.bondType,
        bid_offer: modifyValues.bidOffer,
        initiator_ifsc: modifyValues.initiatorIfsc,
        initiator_bank_account_number: modifyValues.initiatorBankAccountNumber,
        initiator_dp_type: modifyValues.initiatorDpType,
        initiator_dpid: modifyValues.initiatorDpid,
        initiator_client_id: modifyValues.initiatorClientId,
        settlement_date: moment(
          modifyValues.settlementDate,
          "YYYY-MM-DD"
        ).format("MMM DD, YYYY"),
        settlement_type: modifyValues.settlementType,
      }
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("RFQ Modified Internally");
          setLoading(true);
          setOpen(false);
          setInternally(false);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const generatePaymentLink = () => {
    makePOSTRequestOnUserService(
      `/order_management_v2/rfq_generatepayment_link/`,
      {
        trade_date: moment().format("DD/MM/YYYY"),
        order_no: `${orderDetails?.payment_link_order_number}`,
      }
    )
      .then((response) => {
        if (response && response.status === 200) {
          const { data } = response;
          if (data.orderNo === "ORDER NO. NOT FOUND") {
            toastr.error("Order Number Not Found, Please try after 5 minutes");
          } else {
            toastr.success("Payment Link Generated Successfully");
          }
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleCancelRFQ = () => {
    makePUTRequestOnUserService(
      `/order_management_v2/orders/cancel/${orderUUID}/`,
      { admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name }
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("RFQ Cancelled Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const handleOrderStatus = () => {
    makePUTRequestOnUserService(
      `/order_management_v2/smestorders/order/update/?status=${dealStatus}/`,
      {
        status: dealStatus,
        uuid: orderUUID,
        admin_name: JSON.parse(localStorage.getItem("loginUser")!)?.name,
      }
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Deal status changed Successfully");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const downloadDealsheet = () => {
    downloadUserServiceFileUsingGET(
      `/digio/download_pdf/${orderDetails?.rfq_deal_id}/deal/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Deal Sheet Downloaded Successfully");
          saveAs(response.data, `Dealsheet_${orderDetails?.rfq_deal_id}.pdf`);
        }
      })
      .catch((error) => toastr.error(error));
  };
  const downloadOrdersheet = () => {
    downloadUserServiceFileUsingGET(
      `/digio/download_pdf/${orderDetails?.rfq_deal_id}/order/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          toastr.success("Order Sheet Downloaded Successfully");
          saveAs(
            response.data,
            `Order_Receipt_${orderDetails?.rfq_deal_id}.pdf`
          );
        }
      })
      .catch((error) => toastr.error(error));
  };
  const downloadFundTransferProof = () => {
    downloadUserServiceFileUsingGET(
      `/order_management_v2/download_fundtransfer/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 200) {
          saveAs(
            response.data,
            `Fund_Transfer_Proof_${orderDetails?.rfq_deal_id}.${(
              orderDetails?.fundtransfer_image_name || ""
            )
              .split(".")
              .pop()!}`
          );
          toastr.success("Fund Transfer Proof Downloaded Successfully");
        }
      })
      .catch((error) => {
        toastr.error(error);
        toastr.warning("Please try after a minute");
      });
  };
  const deleteRFQOrder = () => {
    makeDELETERequestOnUserServiceWithQueryParameter(
      `/order_management_v2/rfq_order/delete/${orderUUID}/`
    )
      .then((response) => {
        if (response && response.status === 204) {
          toastr.success("RFQ order deleted successfully");
          setTimeout(() => {
            props.history.push(`/rfq_orders`);
          }, 2000);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const actionButtons: ActionButtons[] = useMemo(
    () => [
      {
        name: "Add RFQ Quote",
        color: "info",
        icon: <PlaylistAddRoundedIcon />,
        disabled: orderDetails?.is_added_on_rfq as boolean,
        helperText: orderDetails?.is_added_on_rfq
          ? "RFQ Added Successfully"
          : "",
        handleClick: () => handleAddRFQ(),
      },
      {
        name: "Modify RFQ Quote",
        color: "warning",
        icon: <EditNoteRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq
          ? true
          : orderDetails?.order_status === "Cancelled" ||
            orderDetails?.order_status === "Confirmed"
          ? true
          : false,
        helperText: "",
        handleClick: () => {
          setModifyValues(initialModifyValues);
          setOpen(true);
        },
      },
      {
        name: "Modify Internally",
        color: "warning",
        icon: <EditNoteRoundedIcon />,
        disabled:
          orderDetails?.order_status === "Cancelled" ||
          orderDetails?.order_status === "Confirmed"
            ? true
            : false,
        helperText: "",
        handleClick: () => {
          setModifyValues(initialModifyValues);
          setOpen(true);
          setInternally(true);
        },
      },
      {
        name: "Cancel RFQ Quote",
        color: "error",
        icon: <DeleteForeverRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq
          ? true
          : orderDetails?.order_status === "Cancelled" ||
            orderDetails?.order_status === "Confirmed"
          ? true
          : false,
        helperText:
          orderDetails?.order_status === "Cancelled" ||
          orderDetails?.order_status === "Confirmed"
            ? `RFQ ${orderDetails?.order_status} Successfully`
            : "",
        handleClick: () => handleCancelRFQ(),
      },
      {
        name: "Accept RFQ Quote",
        color: "success",
        icon: <DoneAllRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq
          ? true
          : orderDetails?.order_status === "Confirmed" ||
            orderDetails?.order_status === "Cancelled"
          ? true
          : false,
        helperText:
          orderDetails?.order_status === "Confirmed" ||
          orderDetails?.order_status === "Cancelled"
            ? `RFQ ${orderDetails?.order_status} Successfully`
            : "",
        handleClick: () => handleAcceptRFQ(),
      },
      {
        name: "Generate Payment Link",
        color: "secondary",
        icon: <AccountBalanceRoundedIcon />,
        disabled: (!orderDetails?.is_added_on_rfq ||
          orderDetails.payment_link) as boolean,
        helperText:
          "After Accepting RFQ, Please Generate payment Link after 5 minutes.",
        handleClick: () => generatePaymentLink(),
      },
      {
        name: "Download Order Receipt",
        color: "secondary",
        icon: <DownloadRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq,
        helperText: "",
        handleClick: () => downloadOrdersheet(),
      },
      {
        name: "Download Deal Confirmation",
        color: "secondary",
        icon: <DownloadRoundedIcon />,
        disabled: !orderDetails?.is_added_on_rfq,
        helperText: "",
        handleClick: () => downloadDealsheet(),
      },
      {
        name: "Download Fund Transfer Proof",
        color: "secondary",
        icon: <DownloadRoundedIcon />,
        disabled: !orderDetails?.isfundtransfer_Uploaded,
        helperText: "",
        handleClick: () => downloadFundTransferProof(),
      },
      JSON.parse(localStorage.getItem("loginUser")!)?.isSuperuser && {
        name: "Delete RFQ Order",
        color: "error",
        icon: <DeleteForeverRoundedIcon />,
        helperText: "",
        handleClick: () => deleteRFQOrder(),
      },
    ],
    [orderDetails]
  );

  const rfqData = useMemo(
    () => [
      { label: "RFQ Deal ID", icon: "none", value: orderDetails?.rfq_deal_id },
      {
        label: "RFQ Order Number",
        icon: "none",
        value: orderDetails?.rfq_order_number,
      },
      {
        label: "Order Number",
        icon: "none",
        value: orderDetails?.payment_link_order_number,
        helperText: "Order Number used to generate payment link",
      },
      {
        label: "Date",
        icon: "edit",
        value: moment(orderDetails?.insertedAt).format(
          "(HH[H]:mm[M]) MMM DD, YYYY"
        ),
      },
      { label: "No. of Bonds", icon: "edit", value: orderDetails?.no_of_bonds },
      {
        label: "Payment Process",
        icon: "edit",
        value: orderDetails?.payment_process,
      },
      {
        label: "Lead Owner",
        icon: "none",
        value: orderDetails?.leadowner,
      },
    ],
    [orderDetails]
  );

  const ifaData = useMemo(
    () => [
      { label: "Source", icon: "none", value: null },
      { label: "Bond Type", icon: "edit", value: orderDetails?.bond_type },
      { label: "Deal Type", icon: "edit", value: orderDetails?.deal_type },
      { label: "Bid or Offer", icon: "edit", value: orderDetails?.bid_offer },
      {
        label: "ISIN",
        icon: "edit",
        value: orderDetails?.isin,
        helperText: orderDetails?.security_name,
      },
      { label: "Value", icon: "edit", value: orderDetails?.value },
      { label: "Yield Type", icon: "edit", value: orderDetails?.type_of_yield },
      { label: "Yield", icon: "edit", value: orderDetails?.sell_yield },
      { label: "Price", icon: "edit", value: orderDetails?.sell_price },
      {
        label: "Settlement Type",
        icon: "none",
        value: orderDetails?.settlement_type,
        helperText: `Settlement Date: ${orderDetails?.settlement_date}`,
      },
      {
        label: "Principal Amount",
        icon: "none",
        value: orderDetails?.principal_amount,
      },
      { label: "Accrued Interest (from BSE)", icon: "none", value: null },
      {
        label: "Modified Accrued Interest",
        icon: "edit",
        value: orderDetails?.modified_accrued_interest,
        helperText: `Accured Interest Calculation as on: ${orderDetails?.settlement_date}`,
      },
      { label: "Stamp Duty", icon: "none", value: orderDetails?.stamp_duty },
      {
        label: "Total Consideration without Stamp Duty",
        icon: "none",
        value: orderDetails?.total_consideration,
        helperText: `Total Consideration with Stamp Duty - ${
          orderDetails?.total_consideration! + orderDetails?.stamp_duty!
        }`,
      },
      {
        label: "Buyer Name (BSE)",
        icon: "none",
        value: orderDetails?.buyer_client_name,
      },
      { label: "Seller Name", icon: "none", value: orderDetails?.seller_name },
      {
        label: "Initiator IFSC",
        icon: "none",
        value: orderDetails?.initiator_ifsc,
      },
      {
        label: "Initiator Bank Account Number",
        icon: "none",
        value: orderDetails?.initiator_bank_account_number,
      },
      {
        label: "Initiator DP Type",
        icon: "none",
        value: orderDetails?.initiator_dp_type,
      },
      {
        label: "Initiator DPID",
        icon: "none",
        value: orderDetails?.initiator_dpid,
      },
      {
        label: "Initiator Client ID",
        icon: "none",
        value: orderDetails?.initiator_client_id,
      },
      {
        label: "Buyer Full Name",
        icon: "none",
        value: orderDetails?.buyer_full_name,
      },
      {
        label: "Payment Link",
        icon: "copy",
        value: orderDetails?.payment_link,
      },
    ],
    [orderDetails]
  );

  const additionalInfo = useMemo(
    () => [
      { label: "Rating", icon: "none", value: orderDetails?.rating },
      {
        label: "Rating Agency",
        icon: "none",
        value: orderDetails?.rating_agency,
      },
      {
        label: "Minimum Order Value",
        icon: "none",
        value: orderDetails?.minimum_order_value,
      },
      { label: "GFD", icon: "none", value: orderDetails?.gfd },
      {
        label: "Deal Time Hours",
        icon: "none",
        value: orderDetails?.deal_time_hours,
      },
      {
        label: "Deal Time Minutes",
        icon: "none",
        value: orderDetails?.deal_time_minutes,
      },
      { label: "OTOOTM", icon: "none", value: orderDetails?.otootm },
      {
        label: "OTO Participant Name",
        icon: "none",
        value: orderDetails?.oto_participant_name,
      },
      { label: "ProClient", icon: "none", value: orderDetails?.pro_client },
      {
        label: "Initiator Name",
        icon: "none",
        value: orderDetails?.initiator_name,
      },
      {
        label: "DirectBrokered",
        icon: "none",
        value: orderDetails?.direct_brokered,
      },
      {
        label: "Negotiable Flag",
        icon: "none",
        value: orderDetails?.negotiable_flag,
      },
      {
        label: "Seller Broker Name",
        icon: "none",
        value: orderDetails?.seller_broker_name,
      },
      {
        label: "Buyer Broker Name",
        icon: "none",
        value: orderDetails?.buyer_broker_name,
      },
      {
        label: "Disclosed Identity",
        icon: "none",
        value: orderDetails?.disclosed_identity,
      },
      {
        label: "Initiator Custodian",
        icon: "none",
        value: orderDetails?.initiator_custodian,
      },
      {
        label: "Responder Custodian",
        icon: "none",
        value: null,
      },
      {
        label: "Responder IFSC",
        icon: "none",
        value: orderDetails?.responder_ifsc,
      },
      {
        label: "Responder Bank Account Number",
        icon: "none",
        value: orderDetails?.responder_bank_account_number,
      },
      {
        label: "Responder DP Type",
        icon: "none",
        value: orderDetails?.responder_dp_type,
      },
      {
        label: "Responder DPID",
        icon: "none",
        value: orderDetails?.responder_dpid,
      },
      {
        label: "Responder Client ID",
        icon: "none",
        value: orderDetails?.responder_client_id,
      },
    ],
    [orderDetails]
  );

  const webhooks = useMemo(
    () => [
      {
        label: "Pay In Funds",
        status: orderDetails?.fund_credited,
        icon: <DownloadForOfflineTwoToneIcon />,
        color:
          orderDetails?.fund_credited === "RECEIVED" ? "#4fec6e" : "#EC4F56",
      },
      {
        label: "Pay Out Funds",
        status:
          orderDetails?.fund_debited === "RECEIVED"
            ? "GIVEN"
            : orderDetails?.fund_debited,
        icon: <DownloadForOfflineTwoToneIcon sx={{ rotate: "180deg" }} />,
        color:
          orderDetails?.fund_debited === "RECEIVED" ? "#4fec6e" : "#EC4F56",
      },
      {
        label: "Pay In Security",
        status: orderDetails?.security_credited,
        icon: <DownloadForOfflineTwoToneIcon />,
        color:
          orderDetails?.security_credited === "RECEIVED"
            ? "#4fec6e"
            : "#EC4F56",
      },
      {
        label: "Pay Out Security",
        status:
          orderDetails?.security_debited === "RECEIVED"
            ? "GIVEN"
            : orderDetails?.security_debited,
        icon: <DownloadForOfflineTwoToneIcon sx={{ rotate: "180deg" }} />,
        color:
          orderDetails?.security_debited === "RECEIVED" ? "#4fec6e" : "#EC4F56",
      },
      {
        label: "Deal Status",
        status:
          orderDetails?.dealstatus_settled === "RECEIVED"
            ? "SETTLED"
            : orderDetails?.dealstatus_settled,
        icon: <TaskAltIcon />,
        color:
          orderDetails?.dealstatus_settled === "RECEIVED"
            ? "#4fec6e"
            : "#EC4F56",
      },
    ],
    [orderDetails]
  );

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService(
      `/order_management_v2/orders/by-uuid/${orderUUID}/`
    )
      .then((resp) => {
        if (resp.status == 200) {
          setOrderDetails(resp.data);
          setDealStatus(resp.data.deal_status);
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  return (
    <Box sx={{ maxWidth: ["90%", "80%"], mx: "auto", my: 5 }}>
      {loading ? (
        <CircularProgress color="error" />
      ) : (
        <>
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5 }}>
            {(JSON.parse(localStorage.getItem("loginUser")!)?.isSuperuser ||
              JSON.parse(localStorage.getItem("loginUser")!)?.can_see_all) && (
              <Grid container spacing={2}>
                {actionButtons
                  .filter((item) => item.name && item.name.length)
                  .map((button, index) => (
                    <Grid item key={index} xs={6} sm={4} md={"auto"}>
                      <StyledButton
                        fullWidth
                        variant="outlined"
                        startIcon={button.icon}
                        color={button.color}
                        onClick={button.handleClick}
                        disabled={button.disabled}
                      >
                        {button.name}
                      </StyledButton>
                      <Typography
                        sx={{
                          color: "#45E142",
                          fontSize: 12,
                          textAlign: "center",
                          mt: 1,
                        }}
                      >
                        {button.helperText}
                      </Typography>
                    </Grid>
                  ))}
                <Grid item xs={12}>
                  <Box
                    sx={{
                      width: 200,
                      m: "0 auto",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <FormControl fullWidth variant="standard" size="small">
                      <InputLabel
                        sx={{ fontWeight: 800 }}
                        id="deal-status-label"
                      >
                        Deal Status
                      </InputLabel>
                      <Select
                        labelId="deal-status-label"
                        id="deal-status-select"
                        value={dealStatus}
                        label="Deal Status"
                        onChange={handleDealStatusSelect}
                        input={<StyledSelectInput />}
                      >
                        <MenuItem value={"Pending"}>Pending</MenuItem>
                        <MenuItem value={"Confirmed"}>Confirmed</MenuItem>
                        <MenuItem value={"Settled"}>Settled</MenuItem>
                        <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                      </Select>
                    </FormControl>
                    <IconButton
                      sx={{ mb: 0.5, ml: 1, color: "#7659EE", p: 0 }}
                      onClick={handleOrderStatus}
                    >
                      <ArrowCircleRightTwoToneIcon fontSize="large" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            )}
            <Box sx={{ bgcolor: "#7659EE", borderRadius: 2.5, p: 2, mt: 2 }}>
              <Stack
                direction="row"
                spacing={4}
                useFlexGap
                flexWrap="wrap"
                justifyContent="center"
              >
                {webhooks.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      icon={item.icon}
                      label={item.status}
                      sx={{
                        opacity: 0.8,
                        fontWeight: 600,
                        color: "#FFFFFF",
                        bgcolor: item.color,
                        "& .MuiChip-icon": {
                          color: "#FFFFFF",
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        fontSize: 10,
                        fontWeight: 600,
                        mt: 1,
                        color: "#FFFFFF",
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Box>
          <Box sx={{ bgcolor: "#FFFFFF", p: 2, borderRadius: 5, mt: 3 }}>
            <Grid container spacing={2}>
              {rfqData.map((item, index) => (
                <Grid key={index} item xs={12} sm={4} md={3}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor={`${item.label
                        .toLowerCase()
                        .replace(/\s+/g, "-")}`}
                    >
                      {item.label}
                    </InputLabel>
                    <StyledTextInput
                      id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                      disabled
                      value={item.value}
                      endAdornment={
                        <InputAdornment position="end">
                          {item.icon === "edit" ? (
                            <DrawTwoToneIcon />
                          ) : item.icon === "copy" ? (
                            <CopyAllTwoToneIcon />
                          ) : null}
                        </InputAdornment>
                      }
                    />
                    {item.helperText && (
                      <FormHelperText
                        sx={{
                          color: "#45E142",
                          fontSize: 12,
                          ml: 1,
                        }}
                      >
                        {item.helperText}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            <Box
              component="hr"
              sx={{ borderColor: "#7659EE", opacity: 0.5, my: 4 }}
            />
            <Grid container spacing={2}>
              {ifaData.map((item, index) => (
                <Grid key={index} item xs={12} sm={4} md={3}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor={`${item.label
                        .toLowerCase()
                        .replace(/\s+/g, "-")}`}
                    >
                      {item.label}
                    </InputLabel>
                    <StyledTextInput
                      id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                      disabled
                      value={item.value}
                      endAdornment={
                        <InputAdornment position="end">
                          {item.icon === "edit" ? (
                            <DrawTwoToneIcon />
                          ) : item.icon === "copy" ? (
                            <CopyAllTwoToneIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                copy(item.value as string);
                                toastr.success(
                                  "Payment Link copied to clipboard successfully!"
                                );
                              }}
                            />
                          ) : null}
                        </InputAdornment>
                      }
                    />
                    {item.helperText && (
                      <FormHelperText
                        sx={{
                          color: "#45E142",
                          fontSize: 12,
                          ml: 1,
                        }}
                      >
                        {item.helperText}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>
            <Box
              component="hr"
              sx={{ borderColor: "#7659EE", opacity: 0.5, my: 4 }}
            />
            <Chip
              label="Additional Information"
              icon={<ExpandMoreIcon />}
              clickable
              variant="outlined"
              onClick={() => setExpanded(!expanded)}
              sx={{
                fontWeight: 600,
                color: "#7659EE",
                borderColor: "#7659EE",
                bgcolor: alpha("#7659EE", 0.1),
                ":hover": {
                  bgcolor: alpha("#EC4F56", 0.1),
                },
                "& .MuiChip-icon": {
                  rotate: expanded ? "180deg" : "0deg",
                  transition: "linear 0.3s",
                  color: "#EC4F56",
                },
              }}
            />
            <Collapse in={expanded}>
              <Grid container spacing={2} sx={{ mt: 3 }}>
                {additionalInfo.map((item, index) => (
                  <Grid key={index} item xs={12} sm={4} md={3}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel
                        shrink
                        htmlFor={`${item.label
                          .toLowerCase()
                          .replace(/\s+/g, "-")}`}
                      >
                        {item.label}
                      </InputLabel>
                      <StyledTextInput
                        id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                        disabled
                        value={item.value}
                        endAdornment={
                          <InputAdornment position="end">
                            {item.icon === "edit" ? (
                              <DrawTwoToneIcon />
                            ) : item.icon === "copy" ? (
                              <CopyAllTwoToneIcon />
                            ) : null}
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </Collapse>
            <Box
              component="hr"
              sx={{ borderColor: "#7659EE", opacity: 0.5, my: 4 }}
            />
            <Grid container spacing={2}>
              {IFA_FEES.map((item, index) => (
                <Grid key={index} item xs={12} sm={4} md={3}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      shrink
                      htmlFor={`${item.label
                        .toLowerCase()
                        .replace(/\s+/g, "-")}`}
                    >
                      {item.label}
                    </InputLabel>
                    <StyledTextInput
                      id={`${item.label.toLowerCase().replace(/\s+/g, "-")}`}
                      disabled
                      endAdornment={
                        <InputAdornment position="end">
                          {item.icon === "edit" ? (
                            <DrawTwoToneIcon />
                          ) : item.icon === "copy" ? (
                            <CopyAllTwoToneIcon />
                          ) : null}
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </Grid>
              ))}
              <Grid item xs={12} sm={4} md={3}>
                <FormControl fullWidth variant="standard">
                  <InputLabel shrink htmlFor="utr-number">
                    UTR Number
                  </InputLabel>
                  <StyledTextInput
                    id="utr-number"
                    disabled
                    endAdornment={
                      <InputAdornment position="end">
                        <DrawTwoToneIcon />
                        <IconButton sx={{ ml: 1, color: "#7659EE", p: 0 }}>
                          <ArrowCircleRightTwoToneIcon fontSize="large" />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <FormControl fullWidth variant="standard" size="small">
                    <InputLabel
                      sx={{ fontWeight: 800 }}
                      id="invoice-cleared-label"
                    >
                      Invoice Cleared
                    </InputLabel>
                    <Select
                      labelId="invoice-cleared-label"
                      id="invoice-cleared-select"
                      value={invoiceCleared}
                      label="Invoice Cleared"
                      onChange={handleInvoiceClearedSelect}
                      input={<StyledSelectInput />}
                    >
                      <MenuItem value={"No"}>No</MenuItem>
                      <MenuItem value={"Yes"}>Yes</MenuItem>
                    </Select>
                  </FormControl>
                  <IconButton sx={{ mb: 0.5, ml: 1, color: "#7659EE", p: 0 }}>
                    <ArrowCircleRightTwoToneIcon fontSize="large" />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => {
              setOpen(false);
              internally && setInternally(false);
            }}
            maxWidth="sm"
            fullWidth
          >
            <DialogContent>
              <Grid container spacing={1}>
                {Object.keys(initialModifyValues).map((item, index) => (
                  <Grid item xs={6} key={index}>
                    <FormControl fullWidth variant="standard">
                      <InputLabel shrink htmlFor={item}>
                        {item
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, (str) => str.toUpperCase())}{" "}
                        {item === "totalConsideration" && (
                          <Box component="span" sx={{ color: "#e15a42" }}>
                            without stamp duty
                          </Box>
                        )}
                      </InputLabel>
                      <StyledTextInput
                        type={
                          item === "settlementDate"
                            ? "date"
                            : isValueString(item)
                            ? "text"
                            : "number"
                        }
                        id={`${item}_${index}`}
                        name={item}
                        value={modifyValues[item as keyof IModifyValues]}
                        {...(item === "settlementType" && {
                          onInput: (e) => {
                            const allowedValues = ["0", "1", "2"];
                            const inputValue = (e.target as HTMLInputElement)
                              .value;
                            if (!allowedValues.includes(inputValue)) {
                              (e.target as HTMLInputElement).value = "";
                            }
                          },
                        })}
                        onChange={(event) => {
                          const value = event.target.value;
                          setModifyValues((prev) => ({
                            ...prev,
                            [item as keyof IModifyValues]: isValueString(item)
                              ? value
                              : parseFloat(value),
                          }));
                        }}
                      />
                      {item === "totalConsideration" && (
                        <FormHelperText
                          sx={{
                            color: "#45E142",
                            fontSize: 12,
                            ml: 1,
                          }}
                        >
                          With stamp duty{" "}
                          {modifyValues.totalConsideration +
                            modifyValues.stampDuty}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() =>
                  internally ? handleModifyInternally() : handleModify()
                }
              >
                Modify
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
};

export default withRouter(RFQOrderDetails);
