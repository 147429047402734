import React, { useEffect } from "react";
import { TextField, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import DateFnsUtils from "@date-io/date-fns";
import toastr from "toastr";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  textField: {
    width: "184px",
  },
}));

function ifAllFieldsEmpty(currentState) {
  return (
    currentState.nomineeName === "" ||
    currentState.relationship === "" ||
    currentState.mobileNumber === ""
  );
}

export default function AddNewNomineeModal(props) {
  const classes = useStyles();
  const initialState = props.nomineeDetails;
  const [currentState, setCurrentState] = React.useState(initialState);
  const [dateOfBirth, setDateOfBirth] = React.useState(
    initialState.dateOfBirth
  );

  const resetStateAndClose = (event) => {
    event.preventDefault();
    setCurrentState(initialState);
    props.onClose(event);
  };

  const handleChange = (event) => {
    event.preventDefault();
    setCurrentState((previousValues) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDateOnChange = (date) => {
    setDateOfBirth(date);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (ifAllFieldsEmpty(currentState)) {
      toastr.error("All fields are mandatory");
    } else {
      let state = currentState;
      state.dateOfBirth = dateOfBirth;
      setDateOfBirth(new Date());
      setCurrentState(initialState);
      props.onSave(event, currentState);
    }
  };

  return (
    <div>
      <Dialog
        open={props.show}
        onClose={resetStateAndClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Update Nominee Details
        </DialogTitle>
        <DialogContent style={{ width: "500px" }}>
          <DialogContentText id="alert-dialog-description">
            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <FormControl
                variant="outlined"
                className={classes.formControl}
                style={{ width: "100%" }}
              >
                <TextField
                  style={{ "margin-top": "10px" }}
                  type="text"
                  name="nomineeName"
                  variant="outlined"
                  fullWidth
                  label="Nominee Name"
                  defaultValue={currentState.nomineeName}
                  onChange={handleChange}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item justify="space-around" xs={8}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Purchase Date"
                    format="dd/MM/yyyy"
                    value={dateOfBirth}
                    onChange={handleDateOnChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="relationship"
                variant="outlined"
                fullWidth
                label="Nominee Relationship"
                defaultValue={currentState.relationship}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} style={{ "margin-top": "10px" }}>
              <TextField
                style={{ "margin-top": "10px" }}
                type="text"
                name="mobileNumber"
                variant="outlined"
                fullWidth
                label="Mobile Number"
                defaultValue={currentState.mobileNumber}
                onChange={handleChange}
              />
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={resetStateAndClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
